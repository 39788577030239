<template>
  <div class="user_content">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-tabs v-model="type" style="border-top: 1px solid #eee;" @click="handleTabClick">
        <van-tab v-for="(item,index) in tabList" :key="index" :title="item.name" :name="item.value" />
      </van-tabs>
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-if="!list.length" class="ordernull">
          <van-empty
            image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
            description="暂无数据"
          />
        </div>
        <div v-else class="orderlist">
          <ul>
            <li v-for="(item,index) in list" :key="index">

              <div class="ordernum">订单号: <span id="ordernumber">{{ item.order_sn }}</span>
                <span
                  class="copy"
                  @click="copy(item.order_sn)"
                >复制</span>
              </div>
              <div class="ordercen">
                <div class="ordercenImg">
                  <imgLoading :src="item.goods_thumbnail_url" />
                </div>
                <div>
                  <div class="title"><img :src="item.icon" alt="">{{ item.goods_name }}</div>
                  <div class="Price">
                    <div>
                      <p>付款金额</p>
                      <p><span>￥</span>{{ item.order_amount }}</p>
                    </div>
                    <div style="text-align: right">
                      <p>入园日期</p>
                      <p style="color: #333;font-size: 10px;">{{ item.order_amount_time }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="orderfoo">
                <div>{{ item.order_create_time }}</div>
                <div>{{ item.cents_status_text }}</div>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from 'vue'
import { DropdownItem, PullRefresh, DropdownMenu, Empty, List, Tab, Tabs, Toast } from 'vant'
import { fetchOrderListByUniversalStudios } from '@/services/order'
import ImgLoading from '@/component/imgLoading'
Vue.use(Tab).use(Tabs).use(DropdownMenu).use(DropdownItem).use(Toast).use(Empty).use(List)

export default {
  components: {
    'VanPullRefresh': PullRefresh,
    ImgLoading
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      error: false,
      page: 1,
      type: 0,
      list: [],
      tabList: [
        { name: '全部', value: 0 },
        { name: '待使用', value: 1 },
        { name: '已使用', value: 2 }
      ]
    }
  },
  created() {
    this.page = 1
    this.getOrderListByUniversalStudios()
  },
  mounted() {
  },
  methods: {

    async getOrderListByUniversalStudios() {
      const { type, page } = this
      const mid = localStorage.getItem('uid')
      // const mid = 73240
      const params = {
        page,
        mid,
        type
      }
      const { code, data } = await fetchOrderListByUniversalStudios(params)
      this.loading = false
      if (code === 200) {
        if (this.refreshing) {
          this.list = []
          this.refreshing = false
        }

        this.list = this.list.concat(data.data)
        this.finished = this.list.length >= data.total
      } else {
        this.error = true
        this.page--
      }
    },

    handleTabClick(t) {
      this.page = 1
      this.loading = true
      this.getOrderListByUniversalStudios()
    },
    /**
     * 刷新列表
     * 2021-07-16 13:51:20
     * @author SnowRock
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // this.goodsList = []
      this.page = 1

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getOrderListByUniversalStudios()
    },
    /**
     * 刷新列表
     * 2021-07-16 13:51:20
     * @author SnowRock
     */
    onLoad() {
      this.page++
      this.loading = true
      this.getOrderListByUniversalStudios()
    }
  }
}
</script>

<style lang="less" scoped>

.user_content {
  background: #f6f6f6;
  height: 100vh;

  .choice {
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
      flex: 10;
    }

    & > div:nth-child(2) {
      flex: 2;
      padding: 15px;
      background: #fff;
    }
  }

  .ordernull {
    padding: 13px;
  }

  .orderlist {
    & > ul li {
      background: #FFFFFF;
      padding: 10px;
      margin: 13px;

      .ordernum {
        color: #333333;
        font-size: 11px;
        padding: 4px 0 10px ;
        text-align: left;
        border-bottom: 1px solid #ECECEC;

        .copy {
          margin-left: 10px;
          padding: 4px 10px;
          border-radius: 4px;
          border: 1px solid #F6F6F6;
        }
      }

      .ordercen {
        display: flex;

        .ordercenImg {
          max-width: 90px;
          max-height: 90px;
        }

        padding: 10px 0 4px;
        & > div:nth-child(1) {
          img {
            max-width: 80px;
            max-height: 80px;
          }
        }

        & > div:nth-child(2) {
          width: 100%;
          margin: 10px;

          .title {
            display: flex;
            align-content: center;
            color: #333333;
            font-size: 13px;
            text-align: left;
            line-height: 20px;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            height: 40px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            & > img {
              width: 16px;
              height: 16px;
            }
          }

          .Price {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            text-align: center;
            padding-right: 5px;

            & > div {
              & > p:nth-child(1) {
                color: #666666;
                font-size: 12px;
              }

              & > p:nth-child(2) {
                color: #E33F44;
                font-size: 15px;
                line-height: 20px;
              }
              & span {
                font-size: 11px;
              }
            }
          }
        }
      }

      .orderfoo {
        display: flex;
        justify-content: space-between;

        & > div:nth-child(1) {
          color: #999999;
          font-size: 11px;
        }

        & > div:nth-child(2) {
          color: #E33F44;
          font-size: 11px;
        }
      }
    }
  }
}
</style>
